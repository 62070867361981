import Utility from '../../../shared/utility.js';
import EncryptUtility from './encrypt-utility.js';
import AddFavourite from "./addFav.js";
import breadcrumbComp from '../../common/breadcrumb-comp';
import PrinterDetails from '../../common/printer-details';
export default {
  data() {
    return {
      refresh: true,
      backSlash: true,
      actionList:[],
      mainPage:"",
      subPage:"",
      pageUrl:"",
      favouriteList: [],
      showAdd: false,
      userId: EncryptUtility.localStorageDecrypt('userID'),
      autoBCNValid: false,
      partNumber: '',
      primaryLabelSize: '',
      primaryPrinterType: '',
      pass: 1,
      ce: 0,
      selectedProject: '',
      createBCNs: true,
      searchPartNumDet: false,
      turnOnAutoBCNValid: false,
      projectData: [],
      projectSelected: false,
      bcnPrefix: '',
      searchPartNum: false,
      bcnData: [],
      projectName: '',
      partDescription: '',
      noOfUnitsBar: '',
      partDetails: false,
      unitsAvailable: false,
      max50Rule: [
        (v) => !!v || 'Field is required',
        (v) => (v || '').length <= 50 || "Max Length of 50 character"        
      ],
      max10Rule: [
        (v) => !!v || 'Field is required',
        (v) => (v || '').length <= 10 || "Max Length of 10 character"  
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace('/', '');
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {
  },
  methods: {
    //To add or remove Favourite issue
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd,this.userId,this.route)  
    },
    //Reset Function
    resetFunction() {
      this.autoBCNValid = false;
      this.partNumber = '';
      this.pass = 1;
      this.ce = 0;
      this.selectedProject = '';
      this.bcnPrefix = '';
      this.createBCNs = true;
      this.searchPartNumDet = false;
      this.turnOnAutoBCNValid = false;
      this.projectData = [];
      this.projectSelected = false;
      this.searchPartNum = false;
      this.bcnData = [];
      this.projectName = '';
      this.partDescription = '';
      this.noOfUnitsBar = '';
      this.partDetails = false;
      this.unitsAvailable = false;
      this.$refs.AutoBCNForm.resetValidation();
      this.$refs.TurnOnAutoBCNForm.resetValidation();
    },
    //Reprint LPN Label based on LPN
    searchPartNumber(){
      if (!this.$refs.AutoBCNForm.validate()) return false;
      this.pass = 1;
      this.ce = 0;
      this.selectedProject = '0';
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let searchPartNum = {
        pass: this.pass,
        UserId: parseInt(this.userId),
        ce: this.ce,
        part_num: this.partNumber,
        project_id: parseInt(this.selectedProject),
        prefix: this.bcnPrefix,
        create_bcns: this.createBCNs==true?1:0,
      };
      this.axios
        .post('/ut/turn_on_autobcn', searchPartNum)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            this.searchPartNum = true;
            this.searchPartNumDet = true;
            this.projectData = responsedata.ProjInfo;
            if(this.projectData.length == 1){
              this.selectedProject = responsedata.ProjInfo[0].project_id;
              this.projectChange();
            }
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    projectChange(){
      this.pass = 2;
      this.ce = 0;
      this.projectSelected = true;
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let searchPartDet = {
        pass: this.pass,
        UserId: parseInt(this.userId),
        ce: this.ce,
        part_num: this.partNumber,
        project_id: parseInt(this.selectedProject),
        prefix: this.bcnPrefix,
        create_bcns: this.createBCNs==true?1:0,
      };
      this.axios
        .post('/ut/turn_on_autobcn', searchPartDet)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            this.bcnData = responsedata.PartBCNInfo;
            let projectDetails = this.projectData.filter((obj) => obj.project_id === this.selectedProject);
            this.projectName = projectDetails[0].project;
            this.partDescription = this.bcnData[0].part_desc;
            this.noOfUnitsBar = this.bcnData[0].units_available_to_bcn;
            this.createBCNs = this.bcnData[0].already_bcned;
            if(this.noOfUnitsBar == 0){
              this.unitsAvailable = true;
            }else{
              this.unitsAvailable = false;
            }
            this.partDetails = true;
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    createBCNSubmit(){
      this.pass = 3;
      this.ce = 0;
      if (this.$refs.TurnOnAutoBCNForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: 'Please Wait...',
        };
        this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        let submitPartDet = {
          pass: this.pass,
          UserId: parseInt(this.userId),
          ce: this.ce,
          part_num: this.partNumber,
          project_id: parseInt(this.selectedProject),
          prefix: this.bcnPrefix,
          create_bcns: this.createBCNs==true?1:0,
        };
        this.axios
          .post('/ut/turn_on_autobcn', submitPartDet)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              let responsedata = [];
              responsedata = JSON.parse(response.data.body.message);
              let Alert = {
                type: 'success',
                isShow: true,
                message: responsedata.Info,
              };
              this.$store.commit('ConfigModule/Alert', Alert);
              this.resetFunction();
              LoaderDialog.visible = false;
              this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
              let Alert = {
                type: 'error',
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit('ConfigModule/Alert', Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit('ConfigModule/Alert', Alert);
            this.errorMessage = error.message;
          });
      }
    },
  },
  components: {
    breadcrumbComp,
    PrinterDetails,
  },
};
